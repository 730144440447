@import "../../styles/mixins";
@import "../../styles/typography";
@import "../../styles/responsive";

.closeIcon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.closeIcon__background {
  fill: $vroom-teal;
}

.closeIcon:hover .closeIcon__background {
  fill: $vroom-teal-hover;
}
