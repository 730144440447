@import '../styles/typography';
@import '../styles/colors';
@import '../styles/responsive';

.mailingListButton {
  display: block;
  width: 100%;

  .mailingListButton__button {
    @extend .linkText;
    display: flex;
    color: $vroom-teal;
    stroke: $vroom-teal;
    margin: 0px;
    padding: 0px;
    text-transform: none;
    border-radius: 0;
    vertical-align: top;
    border: none;
    background: none;

    &:hover {
      color: $vroom-teal-hover;
      stroke: $vroom-teal-hover;
      background: none;
    }
  }

  .mailingListButton__title {
    text-align: left;
    max-width: 280px;
    margin-left: 20px;
  }
}

.mailingListButton__container {
  position: relative;
  @include only-on(mdDown) {
    text-align: center;
  }
}
