.menuIcon {
  width: 25px;
  height: 25px;
}

.menuIconLine {
  transition: transform 0.4s ease-in-out;
}

.menuIconLine-1 {
  transform-origin: top left;
}

.menuIconLine-2 {
  transform-origin: center;
}

.menuIconLine-3 {
  transform-origin: bottom left;
}

.menuIcon--open {
  .menuIconLine-1 {
    transform: rotate(45deg) scale(1);
  }

  .menuIconLine-2 {
    transform: scaleX(0);
  }

  .menuIconLine-3 {
    transform: rotate(-45deg) scale(1);
  }
}
