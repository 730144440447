$offset: 187;
$duration: 1.4s;

.loadingIcon {
  display: block;
  width: 100px;
  margin: 50px auto;
  animation: rotator $duration linear infinite;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

.circleIcon__path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash $duration ease-in-out infinite, colors ($duration * 5) ease-in-out infinite;
}

@keyframes colors {
  0% {
    stroke: #00b9b4;
  }
  20% {
    stroke: #00b9b4;
  }
  25% {
    stroke: #f54641;
  }
  40% {
    stroke: #f54641;
  }
  45% {
    stroke: #ffb900;
  }
  60% {
    stroke: #ffb900;
  }
  65% {
    stroke: #7dd246;
  }
  80% {
    stroke: #7dd246;
  }
  85% {
    stroke: #d255c3;
  }
  100% {
    stroke: #d255c3;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }
  50% {
    stroke-dashoffset: calc($offset / 4);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}
